<template>
  <div>
    <h3 class="mb-5">Select Customer</h3>
    <v-autocomplete
      v-model="customer"
      label="Select customer..."
      outlined
      dense
      :items="customers"
      item-text="customer_name"
      return-object
      @change="$emit('passCustomerSelect', $event)"
    >
    </v-autocomplete>
    <AllocationBackBtn @passBack="$emit('passBack', $event)" v-if="!customer" />
  </div>
</template>
<script>
import db from "../../../../../components/firebaseInit";
export default {
  name: "CustomerPaymentsAutocomplete",
  components: {
    AllocationBackBtn: () => import("../AllocationBackBtn.vue"),
  },
  data() {
    return {
      customer: null,
      customers: [],
    };
  },
  created() {
    this.getCustomers();
  },
  methods: {
    getCustomers() {
      const docRef = db.collection("customers").orderBy("customer_name");
      docRef.onSnapshot((querySnapshot) => {
        this.customers = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.customers.push(data);
        });
      });
    },
  },
};
</script>